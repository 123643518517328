import { environment } from '../../../environments/environment.prod';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DeleteModelRequest, ResetPasswordVm } from '../models/base.model';
import { User } from '../models/user.model';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    baseUrl = `${environment.apiUrl}/api/admin/`;

    constructor(private http: HttpClient) {
    }
    changepassword(passwordModel: any): Observable<any> {
        const url = this.baseUrl + "changepassword";
        return this.http
            .post<any>(url, passwordModel)
            .pipe();
    }

    taoUser(user: User): Observable<User> {
        return this.http
          .post<User>(this.baseUrl + "createuser", user)
          .pipe();
      }
    
      layDanhSachUser(tenantId: any): Observable<User[]> {
        return this.http
          .get<User[]>(this.baseUrl + "getdsusers?tenantId=" + tenantId)
          .pipe(catchError(() => of([])));
      }
    
      capNhatUser(user: any): Observable<any> {
        const url = this.baseUrl + "edituser";
        return this.http
          .post<any>(url, user)
          .pipe();
      }
    
      xoaUser(request: DeleteModelRequest): Observable<any> {
        const url = this.baseUrl + "deleteuser";
        return this.http.post(url, request);
      }
}

export class ResetPasswordVm{
    CurrentPassword: string;
    Password: string;
    ConfirmPassword: string;
}
export class PortalBaoCaoRequest{
    TenDonVi: string = "CÔNG TY CỔ PHẦN BẾN XE QUẢNG NINH";
    TuNgay: string;
    DenNgay: string;
    TuNgayString: string;
    DenNgayString: string;
}
export class DeleteModelRequest{
    Id: number;
    Guid: string;
    TenantId: number;
    TenantCode: string;
    StringId: string;
}

export class PagingRequest{
    PageIndex: number;
    PageSize: number;
    searchText: string;
    searchDate: string;
    Status: boolean;
}
import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        id: 1,
        label: 'THÔNG TIN',
        isTitle: true
    },
    {
        id: 2,
        label: 'BẢNG ĐIỀU KHIỂN',
        icon: 'bx bxs-dashboard',
        link: '/home',
    },
    {
        id: 3,
        label: 'HOẠT ĐỘNG',
        icon: 'bx bxs-zap',
        link: '/dashboard'
    },
    {
        id: 3,
        label: 'QUẢN LÝ ĐƠN VỊ',
        icon: 'bx bxs-briefcase-alt',
        link: '/tenant'
    },
    {
        id: 8,
        label: 'BÁO CÁO',
        icon: 'bx bx-notepad',
        link: '/report'
    },
    {
        id: 4,
        label: 'HỆ THỐNG',
        isTitle: true,
        role: 'SuperAdmin'
    },
    {
        id: 5,
        label: 'PHÂN NHÓM ĐƠN VỊ',
        icon: 'bx bx-cube-alt',
        link: '/tenantgroup',
        role: 'SuperAdmin'
    },
    {
        id: 6,
        label: 'CỔNG THÀNH PHẦN',
        icon: 'bx bx-book-content',
        link: '/portalgroup',
        role: 'SuperAdmin'
    },
    {
        id: 7,
        isLayout: true
    },
];

